import IDM from '@creator.co/module-identity-client'
import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { FeatureContext } from './FeatureContext'
import { API_URL, AUTH_DASHBOARD_DOMAIN, IDM_URL } from '../constants'

export const GlobalContext = createContext(null)

export const GlobalContextProvider = props => {
  const [idm, setIdm] = useState(null)
  const [idmToken, setIdmToken] = useState(null)
  const [menuExpanded, setMenuExpanded] = useState(false)
  const cookies = new Cookies()
  const { flags, hasFeatureFlag } = useContext(FeatureContext)

  const setupAxiosInterceptor = idmToken => {
    axios.interceptors.request.use(
      function (config) {
        if (!config.headers.Authorization) {
          if (idmToken) {
            config.headers.Authorization = `Bearer ${idmToken}`
          } else {
            config.headers.Authorization = undefined
          }
        }
        config.headers['X-Experimental-Feature-Flag'] = flags.join(',')

        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )
  }

  useEffect(() => {
    const client = new IDM({
      apiEndpoint: IDM_URL,
      externalAuthDomain: AUTH_DASHBOARD_DOMAIN,
      externalAuthPath: '/login',
      externalAuth: true,
    })

    if (!hasFeatureFlag('idm')) {
      setIdmToken(cookies.get('idm_token_v1'))
      setIdm(client)
    } else {
      // Attempt to load session
      client.load().then(async loaded => {
        console.log('IDM is logged in?', await client.isLogged(), { loaded })

        if (loaded) {
          setIdmToken(await client.session.getToken())
        }

        setIdm(client)
      })
    }
  }, [])

  const toggleMenu = () => setMenuExpanded(!menuExpanded)

  const getCategories = async () => {
    const { data } = await axios.get(`${API_URL}/categories`)
    return data
  }

  const contextValues = {
    idm,
    idmToken,
    toggleMenu,
    setIdmToken,
    menuExpanded,
    getCategories,
    setupAxiosInterceptor,
  }

  return <GlobalContext.Provider value={contextValues}>{props.children}</GlobalContext.Provider>
}
