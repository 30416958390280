import React, { createContext, useState } from 'react'
import Cookies from 'universal-cookie'

export const FeatureContext = createContext(null)

export const FeatureContextProvider = props => {
  const cookies = new Cookies()
  const [flags, setFlags] = useState([])

  const hasFeatureFlag = flag => {
    const flags = getFeatureFlag()
    setFlags(flags)
    return flag && flags.includes(flag?.toLowerCase())
  }

  const getFeatureFlag = () => {
    const featureFlag = getCookie('featureFlag')
    const featureFlagArray = featureFlag?.split(',').map(ff => ff.toLowerCase())
    return featureFlagArray || []
  }

  const getCookie = key => {
    return cookies.get(key)
  }

  const contextValues = {
    flags,
    getCookie,
    hasFeatureFlag,
  }

  return <FeatureContext.Provider value={contextValues}>{props.children}</FeatureContext.Provider>
}
